<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header headerName="巴林右旗动物免疫台账" @selectJob="selectJob" />

    <div class="w_full h_full-60">
      <el-tabs class="w_full h_full ph_10" v-model="activeName">
        <!-- 区域 免疫台账 -->
        <el-tab-pane class="full" label="汇总" name="first" style="height: 100% !important;">
          <div class="full flex_row_between bgc_fff p_10">
            <div class="w_300 h_full scroll_box overflow_y_auto p_10">
              <el-tree 
                :data="areaTree" 
                accordion
                :props="props" 
                empty-text="数据加载中"
                node-key="id"
                :default-expanded-keys="[1]"
                :highlight-current="true"
                @node-click="handleNodeClickTabel1"
                highlight-current
                :expand-on-click-node="true"
                @node-expand="handleNodeExpand" 
              />
            </div>

            <div class="w_full-200 h_full-40">
              <div class="w_full flex_row_start flex_wrap relative">
                <!-- 时间段 -->
                <div class="h_30 lh_30">
                  时间段
                  <el-date-picker
                    v-model="queryParams1.dateRange"
                    class=""
                    size="small"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="selectTable1DateRange"
                  />
                </div>

                <!-- 病种明细 -->
                <div class="h_30 lh_30 ml_10">
                  病种明细
                  <el-select 
                    class="w_70_i" 
                    v-model="queryParams1.mergeVaccin" clearable size="small" placeholder="病种明细"
                    @change="selectMergeVaccinType"
                  >
                    <el-option
                      v-for="(item, index) in mergeVaccinList" :key="index"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                
                <!-- 动物 -->
                <div class="h_30 lh_30 ml_10">
                  动物
                  <el-select 
                    class="w_70_i" 
                    v-model="queryParams1.animalId" clearable size="small" placeholder="动物"
                    @change="selectTable1Animal"
                  >
                    <el-option
                      v-for="(item, index) in animalList" :key="index"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>

                <!-- 疫苗 -->
                <div class="h_30 lh_30 ml_10">
                  疫苗
                  <el-select 
                    class="" 
                    v-model="queryParams1.vaccinumId" clearable size="small" placeholder="选择疫苗"
                    @change="selectTable1Vaccinum"
                  >
                    <el-option
                      v-for="(item, index) in vaccinumList" :key="index"
                      :label="item.nameCn"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>

                <!-- 级别 -->
                <div class="h_30 lh_30 ml_10">
                  级别
                  <el-select 
                    class="w_130_i" 
                    v-model="queryParams1.mytzType" clearable size="small" placeholder="请选择级别"
                    @change="selectMytzType"
                  >
                    <el-option
                      v-for="(item, index) in mytzTypeList" :key="index"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>

                <div class="absolute top_0 right_0">
                  <el-button type="success" size="small" @click="exportQuYuMianYiData">导出数据</el-button> 
                </div>
              </div>

              <div class="w_full text_center fw_bold fs_22 pv_5">巴林右旗 {{' ' + table1Name +  ' '}} 免疫台账</div>
              <el-table 
                v-loading="!tableData1.length" 
                element-loading-text="数据加载中..."
                :data="tableData1" border style="width: 100%; height:100% !important" 
                max-height="725"
              >
                <el-table-column type="index" label="序号" width="100" align="center" />
                <el-table-column prop="areaName" label="地区" align="center" />
                <el-table-column prop="areaCode" label="地区编码" width="130" align="center" />
                <el-table-column prop="year" label="年度" width="100" align="center" />
                <el-table-column prop="season" label="季节" width="100" align="center">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.season == 1" type="success">春</el-tag>
                    <el-tag v-else-if="scope.row.season == 2" type="warning">秋</el-tag>
                    <el-tag v-else type="danger">未知</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="animalName" label="免疫动物" width="80" align="center" />
                <el-table-column prop="vaccinumName" label="免疫病种" align="center" />
                <el-table-column prop="animalCount" label="存栏数" align="center" />
                <el-table-column prop="fangYiCount" label="免疫数" align="center" />
              </el-table>

              <div class="w_98_p h_30 m_0_auto text_right bgc_fff absolute right_20 bottom_0">
                <div class="w_50_p absolute right_20 bottom_0 z_99999">
                  <el-pagination
                    @size-change="handleTable1SizeChange"
                    @current-change="handleTable1CurrentChange"
                    :current-page="queryParams1.page"
                    :page-size="queryParams1.size"
                    :page-sizes="[20, 50, 100, 200, 500]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="table_1_total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!-- 免疫记录 -->
        <el-tab-pane class="full" label="明细" name="second" style="height: 100% !important;">
          <div class="full flex_row_between bgc_fff p_10">
            <div class="w_300 h_full scroll_box overflow_y_auto p_10">
              <el-tree 
                :data="areaTree" 
                accordion
                :props="props" 
                empty-text="数据加载中"
                node-key="id"
                :default-expanded-keys="[1]"
                :highlight-current="true"
                @node-click="handleNodeClickTabel2"
                highlight-current
                :expand-on-click-node="true"
                @node-expand="handleNodeExpand" 
              />
            </div>

            <div class="w_full-200 h_full-40">
              <div class="w_full mb_10 flex_row_start flex_wrap relative">
                <!-- 时间段 -->
                <!-- <div class="h_30 lh_30">
                  时间段
                  <el-date-picker
                    v-model="queryParams2.dateRange"
                    class=""
                    size="small"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyyMMdd"
                    @change="selectTable2DateRange"
                  />
                </div> -->

                <!-- 动物 -->
                <div class="h_30 lh_30 ml_10">
                  动物
                  <el-select 
                    class="w_100_i" 
                    v-model="queryParams2.animalId" clearable size="small" placeholder="动物"
                    @change="selectTable2Animal"
                  >
                    <el-option
                      v-for="(item, index) in animalList" :key="index"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>

                <!-- 疫苗 -->
                <div class="h_30 lh_30 ml_10">
                  疫苗
                  <el-select 
                    class="" 
                    v-model="queryParams2.vaccinumId" clearable size="small" placeholder="选择疫苗"
                    @change="selectTable2Vaccinum"
                  >
                    <el-option
                      v-for="(item, index) in vaccinumList" :key="index"
                      :label="item.nameCn"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </div>

                <!-- 牧户姓名、防疫员姓名 电话 搜索 -->
                <div class="w_300 ml_10">
                  <el-input 
                    size="small" 
                    v-model="queryParams2.keyWord" 
                    clearable placeholder="搜索牧户或防疫员" 
                    @keyup.enter.native="searchRecordsYiData()" @clear="clearRecordsYiKeyword"
                  >
                    <el-button slot="append" icon="el-icon-search" @click="searchRecordsYiData()">搜索</el-button>
                  </el-input>
                </div>

                <div class="absolute top_0 right_0">
                  <el-button type="success" size="small" @click="exportMuHuMianYiData">导出数据</el-button> 
                </div>
              </div>

              <div class="w_full text_center fw_bold fs_22 pv_5">巴林右旗 {{' ' + table2Name +  ' '}} 牧户免疫记录</div>

              <el-table 
                v-loading="!tableData2.length" 
                element-loading-text="数据加载中..."
                :data="tableData2" border style="width: 100%; height:100% !important" 
                max-height="725"
              >
                <!-- <el-table-column type="index" label="序号" width="100" align="center" /> -->
                <el-table-column prop="jobName" label="防疫工作" align="center" />
                <el-table-column prop="townName" label="镇（苏木）" align="center" />
                <el-table-column prop="villageName" label="村（嘎查）" align="center" />
                <el-table-column prop="userName" label="牧户" align="center" />
                <el-table-column prop="animalName" label="动物" width="80" align="center" />
                <el-table-column prop="vaccinumName" label="免疫病种" align="left" />
                <el-table-column prop="animalCount" label="存栏数" align="center" />
                <el-table-column prop="fangYiCount" label="免疫数" align="center" />
                <el-table-column prop="vaccinumAmount" label="疫苗总用量" align="center" />
                <el-table-column prop="remark" label="备注（未免疫数）" align="left" />
                <el-table-column prop="vaccinMaker" label="疫苗厂家" align="left" />
                <el-table-column prop="vaccinBatchNumber" label="疫苗批号" />
                <el-table-column prop="" label="免疫日期" width="100">
                  <template slot-scope="scope">
                    {{ formatDate(scope.row.createTime) }}
                  </template>
                </el-table-column>
                <el-table-column prop="antiepidemicPersonName" label="防疫员" align="center" />
                <el-table-column prop="" label="防疫员签字" align="center">
                  <template slot-scope="scope">
                    <div class="w_50 h_50 relative">
                      <div class="w_50 h_50 absolute top_0 left_0 z_999">
                        <el-image
                          class="full"
                          :src="filePath + scope.row.fyySignPicture2"
                          :preview-src-list="srcList"
                          :initial-index="0"
                          @click="handleClickFyyImg(scope.$index)"
                        />
                      </div>

                      <!-- 防疫员头像 -->
                      <div class="w_50 h_50 absolute top_0 left_0">
                        <el-image
                          class="full"
                          :src="filePath + scope.row.fyySignPicture1"
                          :preview-src-list="srcList"
                        />
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="" label="牧户签字" align="center">
                  <template slot-scope="scope">
                    <div class="w_50 h_50 relative">
                      <div class="w_50 h_50 absolute top_0 left_0 z_999">
                        <el-image
                          class="full"
                          :src="filePath + scope.row.signPicture2"
                          :preview-src-list="srcList"
                          :initial-index="0"
                          @click="handleClickXuZhuImg(scope.$index)"
                        />
                      </div>

                      <div class="w_50 h_50 absolute top_0 left_0">
                        <el-image
                          class="full"
                          :src="filePath + scope.row.signPicture1"
                          :preview-src-list="srcList"
                        />
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <div class="w_98_p h_30 m_0_auto text_right bgc_fff absolute right_20 bottom_0">
                <div class="w_50_p absolute right_20 bottom_0 z_99999">
                  <el-pagination
                    @size-change="handleTable2SizeChange"
                    @current-change="handleTable2CurrentChange"
                    :current-page="queryParams2.page"
                    :page-size="queryParams2.size"
                    :page-sizes="[20, 50, 100, 200, 500]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="table_2_total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- Excel 导出 dialog -->
    <el-dialog
      title="数据导出中..."
      :visible.sync="exporting"
      width="30%"
    >
      <span>一共 {{ exportExcelData.totalPage }} 页，正在导出 {{ exportExcelData.curentPage }} 页，请稍后...</span>
    </el-dialog>

  </div>
</template>

<script>
  import * as Excel from 'exceljs';
  import { saveAs } from 'file-saver';

  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'
  import geoJsonMapMixin from '@/mixins/geoJson-map.js'
  import imgMixin from '@/mixins/def-img.js'

  import { Message } from 'element-ui'

  import { filePath } from "@/const"

  import { getTownList, getVillageList } from '@/api/base/base.js'
  import { getVaccinumList } from '@/api/fang-yi/yi-miao.js'
  import { 
    getMytzPage,
    getRecordsYiPage,
    filterRecordsYiCount
  } from '@/api/fang-yi/fang-yi.js'
  import { getAnimalList } from '@/api/animal/index.js'

  export default {
    name: "mianYiTaiZhang",
    mixins: [defMixin, componentsMixin, geoJsonMapMixin, imgMixin],
    data() {
      return {
        activeName: 'first',
        jobId: null,
        level: 2,
        areaTree: [
          {
            id: 1,
            parentAreaCode: '150400000000',
            parentLevel: 1,
            level: 2,
            areaCode: '150423000000',
            label: '巴林右旗',
            children: []
          }
        ],

        srcList: [],

        exporting: false,
        exportExcelData: {
          totalPage: 0,
          curentPage: 0
        },

        animalList: [],
        vaccinumList: [],

        mytzTypeList: [
          { name: '总台账', value: 0 },
          { name: '下辖地区台账', value: 1 }
        ],

        mergeVaccinList: [
          { name: '是', value: 0 },
          { name: '否', value: 1 }
        ],

        table1Name: '总',
        tableData1: [],
        table_1_total: 0,
        queryParams1: {
          parentAreaCode: '150423000000',
          parentLevel: 2,
          mergeVaccin: 1,
          mytzType: 0,
          jobId: null,
          areaCode: null,
          animalId: null,
          vaccinumId: null,
          dateRange: null,
          page: 1,
          size: 20
        },

        fangYiYuanList: [],

        table2Name: '',
        tableData2: [],
        table_2_total: 0,
        queryParams2: {
          parentAreaCode: null,
          parentLevel: null,
          areaCode: null,
          'jobId': null,
          dateRange: null,
          animalId: null,
          vaccinumId: null,
          keyWord: null,
          state: 0,
          page: 1,
          size: 20
        },

        props: {
          children: 'children',
          label: 'label',
          isLeaf: 'leaf'
        }
      }
    },

    async mounted() {
      let antiepidemicPersonName = this.$route.params.userName
      
      if (antiepidemicPersonName != 'null') {
        this.queryParams2.keyWord = antiepidemicPersonName
        this.activeName = 'second'
      } 

      // 获取 镇（苏木）
      await this.getTownList()

      // 获取 动物列表
      await this.getAnimalList()

      // 获取 疫苗列表
      await this.getVaccinumList()

      // 获取 区域免疫台账汇总
      this.getMytzPageRes(this.queryParams1)

      // 获取免疫台账明细
      this.getRecordsYiPage(this.queryParams2)
    },

    methods: {
      selectJob(jobId) {
        this.jobId = jobId
        this.jobName = JSON.parse(sessionStorage.job).jobName

        this.queryParams1.jobId = jobId
        this.queryParams2['jobId'] = jobId

        this.getMytzPageRes(this.queryParams1)
      },

      // 获取 镇 （苏木）
      async getTownList() {
        let blyq_countyId = '150423000000'

        let town_res = await getTownList(blyq_countyId)
        if (town_res.code == 1000) {
          let townList = []

          town_res.data.map(async item => {
            townList.push({
              parentAreaCode: '150423000000',
              parentLevel: 2,
              label: item.name,
              townId: item.id,
              areaCode: item.id,
              level: 3,
            })
          })

          this.areaTree[0].children = townList
        }
      },

      // 获取动物列表
      async getAnimalList() {
        let res = await getAnimalList();
        try {
          if (res.code == 1000) {
            this.animalList = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取疫苗列表
      async getVaccinumList() {
        let params = {
          enabled: 1,
          order: "nameCn"
        }
        let res = await getVaccinumList(params);
        try {
          if (res.code == 1000) {
            this.vaccinumList = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 点击 节点
      handleNodeClickTabel1(data) {
        this.level = data.level

        if (data.level == 2) {
          this.table1Name = '总'
        } else if (data.level == 3) {
          this.table1Name = data.label
        } else if (data.level == 4) {
          this.table1Name = data.parentLabel + '-' + data.label
        }

        let params = {
          parentAreaCode: data.parentAreaCode,
          parentLevel: data.parentLevel,
          jobId: this.jobId,
          areaCode: data.areaCode,
          mergeVaccin: this.queryParams1.mergeVaccin,
          mytzType: this.queryParams1.mytzType,
          animalId: null,
          dateRange: null,
          page: 1,
          size: 20
        }

        this.getMytzPageRes(params)
      },

      // 展开节点
      async handleNodeExpand(data) {
        // if (data.level == 3) {
        //   let villageList = []

        //   let res = await getVillageList(data.townId)
        //   if (res.code == 1000) {
        //     res.data.map(item => {
        //       villageList.push({
        //         parentAreaCode: data.townId,
        //         parentLevel: 3,
        //         parentLabel: data.label,
        //         level: 4,
        //         label: item.name,
        //         villageId: item.id,
        //         areaCode: item.id,
        //       })
        //     })
        //   }

        //   for (let i in this.areaTree[0].children) {
        //     if (this.areaTree[0].children[i].townId == data.townId) {
        //       this.areaTree[0].children[i].children = villageList
        //     }
        //   }
        // }
      },



      // ========================= 区域免疫台账相关 ========================

      // 获取 巴林右旗 免疫台账信息
      async getMytzPageRes(params) {
        this.tableData1 = []
        let res = await getMytzPage(params)
        try {
          if (res.code == 1000) {
            this.tableData1 = res.data.list
            this.table_1_total = res.data.pagination.total
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 选择 区域免疫台账 时间区间
      selectTable1DateRange() {
        this.getMytzPageRes(this.queryParams1)
      },

      // 选择 区域免疫台账 病种明细
      selectMergeVaccinType(val) {
        this.getMytzPageRes(this.queryParams1)
      },

      // 选择 区域免疫台账 动物
      selectTable1Animal(val) {
        this.getMytzPageRes(this.queryParams1)
      },

      // 选择 区域免疫台账 疫苗
      selectTable1Vaccinum(val) {
        this.getMytzPageRes(this.queryParams1)
      },

      // 选择 区域免疫台账 级别
      selectMytzType(val) {
        this.table1Name = val == 0 ? '总' : '下辖地区'
        this.getMytzPageRes(this.queryParams1)
      },

      handleTable1SizeChange(val) {
        this.queryParams1.size = val
        this.getMytzPageRes(this.queryParams1)
      },

      handleTable1CurrentChange(val) {
        this.queryParams1.page = val
        this.getMytzPageRes(this.queryParams1)
      },
      
      // 导出 区域免疫台账
      async exportQuYuMianYiData() {
        this.exporting = true

        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('我的工作表');

        worksheet.columns = [
          { header: '地区', key: 'areaName', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '地区编码', key: 'areaCode', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '年度', key: 'year', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '季节', key: 'season', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '免疫动物', key: 'animalName', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '免疫病种', key: 'vaccinumName', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '存栏数', key: 'animalCount', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '免疫数', key: 'fangYiCount', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
        ];

        let page = Math.ceil(this.table_1_total / this.queryParams1.size)
        this.exportExcelData.totalPage = page

        for (let i = 0; i < page; i++) {
          this.exportExcelData.curentPage = i + 1
          let dataList = []
          let params = {
            parentAreaCode: this.queryParams1.parentAreaCode,
            parentLevel: this.queryParams1.parentLevel,
            areaCode: this.queryParams1.areaCode,
            mergeVaccin: this.queryParams1.mergeVaccin,
            mytzType: this.queryParams1.mytzType,
            jobId: this.jobId,
            animalId: this.queryParams1.animalId,
            vaccinumId: this.queryParams1.vaccinumId,
            dateRange: this.queryParams1.dateRange,
            page: i + 1,
            size: this.queryParams1.size
          }

          let res = await getMytzPage(params)
          try {
            if (res.code == 1000) {
              dataList = res.data.list 

              for (let i in dataList) {
                if (dataList[i].season == 1) {
                  dataList[i]['season'] = '春季'
                } else if (dataList[i].season == 2) {
                  dataList[i]['season'] = '秋季'
                } else {
                  dataList[i]['season'] = '未知'
                }

                worksheet.addRow(dataList[i]);
              }
            }
          } catch (err) {
            console.log('获取地区免疫记录失败 : ', err);
          }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, `巴林右旗${' ' + this.table1Name}-${this.jobName}-免疫台账明细.xlsx`);

        this.exporting = false

        Message({
          message: "数据导出成功！",
          type: 'success',
          duration: 1 * 1000
        })
      },






      // ======================== 牧户免疫记录 =========================
      async getRecordsYiPage(params) {
        this.tableData2 = []
        let res = await getRecordsYiPage(params)
        try {
          if (res.code == 1000) {
            this.tableData2 = res.data.list
            this.table_2_total = res.data.pagination.total

            // 初始化 srcList , 否则 需要 第二次点击 图片时 this.srcList 中才有数据
            this.handleClickFyyImg(0)
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 点击 Tree 节点
      handleNodeClickTabel2(data) {
        this.level = data.level

        if (data.level == 2) {
          this.table2Name = ''
        } else if (data.level == 3) {
          this.table2Name = data.label
        } else if (data.level == 4) {
          this.table2Name = data.parentLabel + '-' + data.label
        }

        let params = {
          adminAreaCode: data.areaCode,
          level: data.level,
          'jobId': this.jobId,
          dateRange: this.queryParams2.dateRange,
          animalId: this.queryParams2.animalId,
          vaccinumId: this.queryParams2.vaccinumId,
          keyWord: this.queryParams2.keyWord,
          state: 0,
          page: this.queryParams2.page,
          size: this.queryParams2.size
        }

        this.getRecordsYiPage(params)
      },

      // 选择 免疫明细 时间区间
      selectTable2DateRange(val) {
        this.getRecordsYiPage(this.queryParams2)
      },

      selectTable2Animal(val) {
        this.getRecordsYiPage(this.queryParams2)
      },

      selectTable2Vaccinum(val) {
        this.queryParams2.vaccinumId = val ? val : null
        this.getRecordsYiPage(this.queryParams2)
      },

      searchRecordsYiData() {
        this.getRecordsYiPage(this.queryParams2)
      },

      clearRecordsYiKeyword() {
        this.queryParams2.keyWord = null
        this.getRecordsYiPage(this.queryParams2)
      },

      handleTable2SizeChange(val) {
        this.queryParams2.size = val
        this.getRecordsYiPage(this.queryParams2)
      },

      handleTable2CurrentChange(val) {
        this.queryParams2.page = val
        this.getRecordsYiPage(this.queryParams2)
      },

      // 点击防疫员签到图片
      handleClickFyyImg(index) {
        this.srcList = []
        if (this.tableData2 && this.tableData2.length) {
          let img1 = this.filePath + this.tableData2[index].fyySignPicture1
          let img2 = this.filePath + this.tableData2[index].fyySignPicture2

          let list = [img1, img2]

          this.mergeImgs(list).then((base64) => {
            this.srcList = [base64, img1, img2]
          });
        }
      },

      // 点击畜主签到图片
      handleClickXuZhuImg(index) {
        this.srcList = []
        if (this.tableData2 && this.tableData2.length) {
          let img1 = this.filePath + this.tableData2[index].signPicture1
          let img2 = this.filePath + this.tableData2[index].signPicture2
          let list = [img1, img2]

          this.mergeImgs(list).then((base64) => {
            this.srcList = [base64, img1, img2]
          });
        }
      },

      // 合成图片
      mergeImgs(list, cwidth=480, cheight=640) {
        return new Promise((resolve, reject) => {
          const baseList = [];
          // 创建 canvas 节点并初始化
          const canvas = document.createElement('canvas');

          canvas.width = cwidth + 10;
          canvas.height = cheight

          const context = canvas.getContext('2d');
          list.map((item, index) => {
            const img = new Image();
            // 跨域
            img.crossOrigin = 'Anonymous';
            img.src = item + `?timestamp= ${Date.now()}`;
            img['index'] = index + 1
            img.onload = () => {
              // context.drawImage(img, 0, 0, cwidth * index, cheight)
              context.drawImage(img, 0, 0, cwidth, cheight)
              context.globalAlpha = 1
              context.globalCompositeOperation = "darken"

              const base64 = canvas.toDataURL('image/png');

              baseList.push(base64);

              if (baseList[list.length - 1]) {
                // 返回新的图片
                resolve(baseList[list.length - 1]);
              }
            };
          });
        });
      },

      // 按牧户 导出 免疫台账 （分页 生成 Excel）
      async exportMuHuMianYiData() {
        this.exporting = true

        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('我的工作表');

        worksheet.columns = [
          { header: '工作', key: 'jobName', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '镇/苏木', key: 'townName', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '村/嘎查', key: 'villageName', width: 50, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '牧户', key: 'userName', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '动物', key: 'animalName', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '免疫病种', key: 'vaccinumName', width: 50, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '存栏数', key: 'animalCount', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '免疫数', key: 'fangYiCount', width: 10, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '疫苗用量', key: 'vaccinumAmount', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '疫苗厂家', key: 'vaccinMaker', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '疫苗批号', key: 'vaccinBatchNumber', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '免疫日期', key: 'createTime', width: 30, style: { font: { size: 16, }, alignment: { horizontal: "center" }, border: {} } },
          { header: '防疫员签字', key: 'antiepidemicPersonName', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '畜主签字', key: 'userName2', width: 20, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
          { header: '备注', key: 'remark', width: 130, style: { font: { size: 16, }, alignment: { horizontal: "left" }, border: {} } },
        ];

        let page = Math.ceil(this.table_2_total / this.queryParams2.size)
        this.exportExcelData.totalPage = page

        for (let i = 0; i < page; i++) {
          this.exportExcelData.curentPage = i + 1
          let dataList = []

          let params = {
            adminAreaCode: this.queryParams2.adminAreaCode,
            level: this.queryParams2.level,
            'jobId': this.jobId,
            dateRange: this.queryParams2.dateRange,
            animalId: this.queryParams2.animalId,
            vaccinumId:  this.queryParams2.vaccinumId,
            keyWord: this.queryParams2.keyWord,
            state: 0,
            page: i + 1,
            size: this.queryParams2.size
          }

          let res = await getRecordsYiPage(params)
          try {
            if (res.code == 1000) {
              dataList = res.data.list 

              for (let i in dataList) {
                dataList[i]['userName2'] = dataList[i].userName
                worksheet.addRow(dataList[i]);
              }
            }
          } catch (err) {
            console.log('获取牧户免疫记录失败 : ', err);
          }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `巴林右旗-${this.table2Name}-${this.jobName}-牧户免疫记录.xlsx`);

        this.exporting = false

        Message({
          message: "数据导出成功！",
          type: 'success',
          duration: 1 * 1000
        })
      },


    }
  }
</script>

<style>
.el-pagination__total, .el-pagination__jump { color: orange !important; }

.el-image-viewer__mask { background-color: #fff !important; }

.el-tabs__item { color: #fff !important; }
.el-tabs__item.is-active { color: #409EFF !important; }

.el-tabs__content { height: calc(100% - 50px); }
</style>

